
<template>
    <div class="expressWarp">
        <van-form class="expressInput" @submit="queryExpress">
            <div class="headerImg">
                <img :src="img">
                <div class="inputDiv">
                    <van-field class="inputItem" :maxlength="30" v-model="kddh" label="" placeholder="请输入快递单号"
                               :rules="[{required:true,message:' ',}]"
                    />
                </div>

            </div>
            <div class="queryBtnDiv">
                <van-button class="publicBtn "  :class="{'publicBtnActive':kddh.length>0}" round block native-type="submit">查 询</van-button>
            </div>
        </van-form>

    </div>

</template>

<script>
    import img from '../../assets/img/expressBg.jpg'
    import common from "../../utils/common";
    import {getExpInfo} from "../../utils/tripartiteApi";
    import {uwStatisticAction} from "../../utils/util";
    export default {
        name: "expressInput",
        data(){
            return{
                kddh:'',
                img
            }
        },
        beforeRouteLeave (to, from, next) {
            if (to.name == "expressInput") {
            }else{
                this.$store.dispatch('keepAliveState/removeKeepAlive', 'expressInput')
            }
            next()
        },
        methods:{
            queryExpress(){
                if (this.kddh.length<1){
                    this.$toast("请输入快递单号")
                    return
                }
                getExpInfo({nu:this.kddh}).then(res=>{
                    if(res.code === 200) {
                        if (res.data && res.data.businessCode === 200) {
                            this.$router.push({
                                path:'/expressResult',
                                query:{data:JSON.stringify(res.data.expressData)}
                            })
                        }
                    }
                })
            }
        },
        watch:{
            kddh(newVal){
                this.dabh = newVal.replace(/\s+/g,"")
                console.log("变化")
            },

        },
        mounted() {
            console.log("tokne:",common.getItem('wtToken'))
            uwStatisticAction('/express','快递查询输入')

        },

    }
</script>

<style scoped lang="less">
    .expressWarp{
        padding: 0  0 60px 0 ;
        .queryBtnDiv{
            margin-top:100px ;
            padding: 12px;
        }

    }
    .headerImg{
        position: relative;

        img{
            width: 100%;
            border-bottom-left-radius: 12px;
            border-bottom-right-radius: 12px;
        }
        .inputDiv{
            position: absolute;
            bottom: -30px;
            left: 15px;
            width: calc(100% - 30px);
            background: #FFFFFF;
            box-shadow: 0px 2px 10px 0px rgba(4, 58, 22, 0.2);
            border-radius: 8px;
            padding: 8px 0;
        }


    }

</style>
<style>
    .expressWarp .van-field__control{

        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        /*color: #999999;*/
    }
    .expressWarp .van-field__error-message{
        margin-top: -2px;
    }
    .expressWarp .van-field--error .van-field__control, .van-field--error .van-field__control::placeholder{
        color: #999;
    }

</style>
